<template>
  <div class="merchorderlist">
    <div
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style="padding-right: 0px; padding-left: 0px;"
    >

    <div class="row">
      <div
        class="col-lg-5 col-md-12 col-sm-12 col-xs-12"
        style="padding-bottom: 15px"
      >
        <input
              style=""
              type="text"
              v-model="searchKey"
              class="form-control"
              id="input-store"
              placeholder="Search Order by id"
              value
              name="voucher"
            />
       </div>
      <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12"
        style="padding-bottom: 15px"
      >
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12"
            style="padding-bottom: 15px"
          >
              <div class="row" style="margin-left: 0px; margin-right: 0px;">
                <div
                  class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                  style="padding-right: 0px; padding-left: 0px;"
                >From</div>
                <div
                  class="col-lg-10 col-md-10 col-sm-10 col-xs-10 from-to-date"
                  style="padding-right: 0px; padding-left: 0px;"
                >
                  <datepicker v-model="filterDatefrom" placeholder="Select Date"></datepicker>
                </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="row" style="margin-left: 0px; margin-right: 0px;">
                  <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding-right: 0px;">To</div>
                  <div
                    class="col-lg-10 col-md-10 col-sm-10 col-xs-10 from-to-date"
                    style="padding-right: 0px; padding-left: 0px;"
                  >
                    <datepicker
                      calendar-class="my-datepicker_calendar"
                      v-model="filterDateto"
                      :disabledDates="disabledDates"
                      placeholder="Select Date"
                    ></datepicker>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div class="row" style="margin-left: 0px; margin-right: 0px;">
              <div
                class="col-lg-3 col-md-3 col-sm-3 col-xs-3"
                style="padding-top: 7px;padding-right: 0px; padding-left: 0px;"
              >Payment Method</div>
              <div
                class="col-lg-7 col-md-7 col-sm-7 col-xs-7"
                style="padding-right: 0px; padding-left: 0px;"
              >
                <select v-model="selectedPaymentFilterStatus" class="form-control select-arrow">
                  <option
                    v-for="(item, index) in filterPaymentArray"
                    :key="index"
                    :value="item.payment_type"
                  >{{ item.text }}</option>
                </select>
              </div>
              <div
                class="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                style="padding-right: 0px; padding-left: 0px;text-align: right;"
              >
                <!-- <button @click="getOrders();" class="btn merchprof-sbtn" style="min-height: 41px;">Filter</button>  -->
                <button
                  @click="filterOrderPayMethod()"
                  class="btn merchprof-sbtn merchodr-canbtn"
                  style="min-height: 35px;"
                >Filter</button>
              </div>
            </div>
      </div>
    </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="">
          <h2 class="title">Orders</h2>
        </div>
      </div>
    </div>
    <div id="table-content" class="col-sm-12">
      <table id="example" class="table-order table table-striped table-border-out">
        <thead>
          <tr>
            <!-- <th scope="col" class="head-table"></th> -->
            <th scope="col" class="head-table" style="min-width: 20px;">Order Id</th>
            <!-- <th scope="col" class="head-table">Status</th> -->
            <th scope="col" class="head-table" style="min-width: 20px;">Quantity</th>
            <th scope="col" class="head-table" style="min-width: 20px;">Amount</th>
            <th scope="col" class="head-table" style="min-width: 20px;">Payment Method</th>
            <th scope="col" class="head-table" style="min-width: 20px;">Action</th>
          </tr>
        </thead>
        <tbody>
          <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
              <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
              </div>
            </div>
          </div>

          <tr
            v-for="(itemp, index1) in tableItems"
            :key="index1"
            style="position: relative;min-height: 70px !important;"
          >
            <!-- <td class="text-center col-md-1"><a><img width="70px" :src="itemp.simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail"></a></td> -->
            <td
              class="text-left col-md-4"
              style="vertical-align: middle; height: 80px;padding: 10px;"
            >
              <!-- <div class="row">
                <div class="col-md-12" style="font-size:14px; margin-bottom:5px;">								
                  <a><b>{{ itemp.simple_product_id.product_id.product_name }}</b></a>
                </div>
              </div>-->
              <!-- <div class="row">
                <div class="col-md-6 col-sm-6">								
                  Order ID : {{ itemp.order_env_number }}
                </div>
                <div class="col-md-6 col-sm-6">								
                  Date : {{ itemp.date }}
                </div>
              </div>-->
              <div class="row">
                <div class="col-md-6 col-sm-6" v-if="itemp.order_type != 2">
                  <span style="font-size: 12px;">
                    <b>{{ itemp.order_env_number }}</b>
                  </span>
                  <br />
                  <!-- <span v-if="itemp.order_id.billing_name !== 'undefined undefined'">{{ itemp.order_id.billing_name }}</span> -->
                  {{
                  itemp.user_id != undefined
                  ? itemp.user_id.first_name != undefined
                  ? itemp.user_id.first_name
                  : " "
                  : " "
                  }}
                  {{
                  itemp.user_id != undefined
                  ? itemp.user_id.last_name != undefined
                  ? itemp.user_id.last_name
                  : " "
                  : " "
                  }}
                </div>
                <div class="col-md-6 col-sm-6" v-else>
                  <span style="font-size: 15px;">
                    <b>{{ itemp.order_env_number }}</b>
                  </span>
                  <br />
                  {{
                  itemp.shipping_name != undefined ? itemp.shipping_name : " "
                  }}
                </div>
                <div class="col-md-6 col-sm-6" v-if="itemp.order_type != 2">
                  Date : {{ itemp.date }}
                  <br />
                  Time: {{ itemp.created_time }}
                  <br/>
                  {{
                  itemp.address_id != undefined
                  ? itemp.address_id.city != undefined
                  ? itemp.address_id.city.city_name
                  : " "
                  : " "
                  }}
                </div>
                <div class="col-md-6 col-sm-6" v-else>
                  Date : {{ itemp.date }}
                  <br />
                  {{
                  itemp.city != undefined
                  ? itemp.city.city_name != undefined
                  ? itemp.city.city_name
                  : " "
                  : " "
                  }}
                </div>
              </div>
            </td>

            <td
              class="text-right col-md-1"
              style="vertical-align: middle; text-align: center; font-weight: 700; min-width: 175px;"
            >
              <B>{{ itemp.itemCount + " " }}</B>Items
            </td>

            <td
              class="text-right col-md-1"
              style="vertical-align: middle; text-align: center; font-weight: 700;"
            >
              <B>Rs:</B>
              {{ itemp.subTotalForMearchat }}
            </td>
            <td
              class="text-left col-md-2"
              style="vertical-align: middle; font-weight: 700; text-align: center"
              v-if="itemp.payment_type === 1"
            >Supiri Pola Credit</td>
            <td
              class="text-left col-md-2"
              style="vertical-align: middle; font-weight: 700; text-align: center"
              v-if="itemp.payment_type === 2"
            >Cash On Delivery</td>
            <td
              class="text-left col-md-2"
              style="vertical-align: middle; font-weight: 700; text-align: center"
              v-if="itemp.payment_type === 3"
            >Bank Transfer</td>
            <td
              class="text-left col-md-2"
              style="vertical-align: middle; font-weight: 700; text-align: center"
              v-if="itemp.payment_type === 4"
            >Card Payment</td>

            <td
              class="col-md-4"
              style="vertical-align: middle; text-align: center; min-width: 304px;"
            >
              <button
                type="button"
                data-toggle="tooltip"
                title
                class="btn merchprof-sbtn"
                onclick
                data-original-title="View"
                @click="handdleChange(itemp)"
              >View</button>
              <button
                type="button"
                data-toggle="tooltip"
                title
                :disabled="condition === itemp._id"
                style="margin-left: 10px;margin;  margin-right: 10px;"
                class="btn merchprof-sbtn"
                onclick
                data-original-title="View"
                @click="handlePrint(itemp._id)"
                v-if="userRole !== 5 && userRole !== 6 && userRole !== 7"
              >Print</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right" style="padding-bottom:10px;">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Domain from "@/mixins/domain.js";
import Datepicker from "vuejs-datepicker";
import mixinOrder from "@/mixins/merchant/APIStore";
export default {
  mixins: [mixinOrder],
  components: {
    Datepicker
  },
  props: {
    value: {
      type: String
    }
  },
  data: function() {
    return {
      userRole: "",
      selected_store: "",
      viewType: "all", // data to be viewed in the table (by store name or all orders) all || viewType
      breadcrumb: [
        {
          text: "Cancelled Orders",
          active: false
        }
      ],
      subTotal: 0,
      total: 0,
      condition: "",
      selectedPaymentFilterStatus: "",
      selectedFilterStatusProvince: "0",
      selectedFilterStatusDistrict: "0",
      provinceArray: [{ _id: "0", region_name: "Select Privince" }],
      districtArray: [{ _id: "0", city_name: "Select District" }],
      filterPaymentArray: [
        { payment_type: "", text: "All" },
        // ,{ payment_type:"1", text: "Supiri Pola Credit" }
        { payment_type: "2", text: "Cash On Delivery" },
        { payment_type: "3", text: "Bank Transfer" },
        { payment_type: "4", text: "Card Payment" }
      ],
      inputNumValue: 1,
      no_of_cart_items: 0,
      cart_products: [],
      cartSavedProductIds: [],
      idObj: {
        ids: []
      },
      selectedArray: [],
      pageSize: 25,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      searchKeyStatus: "all",
      tableItems: [],
      storeListData: [],
      pendingOrderArray: [],
      blockLoading: true,
      filterDatefrom: new Date(),
      filterDateto: new Date(),
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 14) - 8640000)
      },
      filterStatusArray: [
        { order_line_status: "", text: "All" },
        { order_line_status: "0", text: "Cancelled" },
        { order_line_status: "1", text: "Pending" },
        { order_line_status: "10", text: "Processing" },
        { order_line_status: "20", text: "Ready to Pickup" },
        { order_line_status: "30", text: "Shipped" },
        { order_line_status: "40", text: "Delivered" },
        { order_line_status: "50", text: "Fail to Delivered" },
        { order_line_status: "60", text: "Return to Store" },
        { order_line_status: "70", text: "Accept Return" },
        { order_line_status: "41", text: "Delivery Confirmed" }
      ],
      selectedFilterStatus: "",
      orderPendingArray: [],
      orderProcessingArray: [],
      orderReadyPickUpArray: [],
      orderShippedArray: [],
      orderWiseArr: [],
      statusFilteroptions: [
        { text: "All", value: "all" },
        { text: "Pending", value: "pending" },
        { text: "Processing", value: "processing" },
        { text: "Ready to pickup", value: "ready" }
      ]
    };
  },
  watch: {
    "$store.state.merchant_selectedstore": function() {
      this.searchKeyStatus = "all";
      this.selectedPaymentFilterStatus = "";
    },
    value(val) {
      this.selected_store = val;
      if (val != -1 && val) {
        this.viewType = "default";
        // this.getOrdersByStore();
        this.getOrders();
      }
      if (val == -1) {
        this.viewType = "all";
        this.getOrders();
      }
    },
    searchKey: function(val) {
      let sType = "orderid";
      this.searchOrders(val, sType);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {},
    filterDatefrom(val) {
      this.searchKeyStatus = "all";
      this.selectedPaymentFilterStatus = "";
      this.getOrders();
      this.disabledDatesSet(val);
    },
    filterDateto(val) {
      this.searchKeyStatus = "all";
      this.selectedPaymentFilterStatus = "";
      this.getOrders();
    },
    filterOrderStatus(val) {
      this.selectedPaymentFilterStatus = "";
    },
    filterOrderPayMethod(val) {
      this.searchKeyStatus = "all";
    }
  },
  created() {
    this.handleBreadcrumb();
    this.filterDatefrom.setDate(this.filterDatefrom.getDate() - 14);
    this.getOrders();
    this.filterPendingOrder();
    // this.getProvince();
    // Added on 21-05-2020
    this.setDefaultStoreToAll();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
  },
  methods: {
    // Added on 21-05-2020
    setDefaultStoreToAll: function() {
      localStorage.setItem("merchant_selectedstore", -1);
      this.$store.dispatch("fetchMerchant_selectedstore");
    },
    filterOrderStatus: function() {
      this.selectedPaymentFilterStatus = "";
      let sType = "status";
      this.searchOrders(this.searchKeyStatus, sType);
    },
    filterOrderPayMethod: function() {
      this.searchKeyStatus = "all";
      let sType = "paymethod";
      this.searchOrders(this.selectedPaymentFilterStatus, sType);
    },
    disabledDatesSet(val) {
      let toDate = val;
      this.filterDateto = null;
      this.disabledDates = {
          to: new Date(new Date(toDate).setFullYear(new Date(toDate).getFullYear(),new Date(toDate).getMonth(),new Date(toDate).getDate()) - 8640000)
      };
    },
    pendigToProcessing: async function(Obj) {
      let response = await this.chgPendingToProcessing(Obj);
    },
    processingToReady: async function(Obj) {
      let response = await this.chgProcessingToReady(Obj);
    },
    readyToShipped: async function(Obj) {
      let response = await this.chgReadyToShipped(Obj);
    },
    filterPendingOrder() {
      if (
        this.$router.history.current.params.type === "Pending Orders" &&
        this.$router.history.current.params.type !== undefined
      ) {
        this.selectedFilterStatus = 1;
        this.getOrders();
      }
    },
    getCities: async function(val) {
      try {
        this.districtArray = await this.getCityList(val);
        this.districtArray.unshift({ _id: "0", city_name: "Select District" });
        this.selectedFilterStatusDistrict = "0";
      } catch (error) {
        throw error;
      }
    },
    getProvince: async function() {
      try {
        this.provinceArray = await this.getProvinceList();
        this.provinceArray.unshift({
          _id: "0",
          region_name: "Select Province"
        });
      } catch (error) {
        throw error;
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    returnactiveColor(status) {
      if (status === 0 || status === 50) {
        return "#e15337";
      }
      if (status === 1 || status === 10) {
        return "#fecd36";
      }
      if (status === 40) {
        return "#4da130";
      }
      return "#005baa";
    },
    getOrdersByStore: async function() {
      try {
        this.blockLoading = true;
        let response = await this.getOrdersByStoreApi(this.selected_store);
        if (response) {
          this.cart_products = response.map(obj => {
            let dats = obj.order_id.date.split("GMT");
            obj.date = dats[0];
            return obj;
          });
          let pagesize = parseInt(this.pageSize);
          this.storeListData = this.cart_products;
          this.tableItems = this.storeListData.slice(
            this.currentPage * pagesize - pagesize,
            this.currentPage * pagesize
          );
          this.rowcount = response.length;
          this.blockLoading = false;
        }
      } catch (e) {
        throw e;
      }
    },
    handlePrint: async function(id) {
      try {
        this.condition = id;
        let res = await this.printOrderDetails(id);
        let domain = Domain.APP_DOMAIN.split("api/v1/")[0] + res.file;
        this.condition = "";
        window.open(domain);
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Specific resource not found.",
          showConfirmButton: false,
          timer: 1800
        });
      }
    },
    getOrdersCount: async function() {
      try {
        let params = "";
        if (this.selectedFilterStatus != "") {
          params += "&order_line_status=" + this.selectedFilterStatus;
        }
        if (this.searchKey != "") {
          params += "&search=" + this.searchKey;
        }
        if (this.selectedPaymentFilterStatus !== "") {
          params += "&payment_type=" + this.selectedPaymentFilterStatus;
        }
        if (
          this.selectedFilterStatusDistrict !== "" &&
          this.selectedFilterStatusDistrict !== "0"
        ) {
          params += "&city=" + this.selectedFilterStatusDistrict;
        }
        params +=
          "&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");
        let responce = await this.getOrderListCount(params);
        this.rowcount = responce.count;
      } catch (error) {
        throw error;
      }
    },
    getOrders: async function() {
      try {
        this.blockLoading = true;
        this.tableItems = [];
        let params = "&page=" + this.currentPage;
        params += "&perpage=" + 25;
        if (this.selectedPaymentFilterStatus !== "") {
          params += "&payment_type=" + this.selectedPaymentFilterStatus;
        }
        if (
          this.selectedFilterStatusDistrict !== "" &&
          this.selectedFilterStatusDistrict !== "0"
        ) {
          params += "&city=" + this.selectedFilterStatusDistrict;
        }
        // if (this.selectedFilterStatus != "") {
          params += "&order_status=0"
        // }
        if (this.searchKey != "") {
          params += "&search=" + this.searchKey;
        }
        params +=
          "&from_date=" + moment(this.filterDatefrom).format("YYYY-MM-DD");
        params += "&to_date=" + moment(this.filterDateto).format("YYYY-MM-DD");

        // Changed 25-05-2020
        let relStoreId = this.selected_store != -1 ? this.selected_store : "";
        params += "&store=" + relStoreId;
        let responce;

        // if (this.viewType === "all") {
        //   responce = await this.getOrderListGroup(params);
        // } else {
        //   responce = await this.getOrdersByStoreApi(this.selected_store);
        // }

        // Changed 21-05-2020
        responce = await this.getOrderList(params);
        // console.log("get merchat array")
        // console.log(responce)

        var orderGroupArray = [];
        var orderWiseStatusArray = [];

        var ordObj = {};

        orderGroupArray = responce;

        orderGroupArray.forEach(element => {
          var ordObj = {};

          ordObj.order_no = element.order_env_number;

          this.orderPendingArray = [];
          this.orderProcessingArray = [];
          this.orderReadyPickUpArray = [];

          element.order_lines.forEach(ele => {
            if (ele.order_line_status == 1) {
              this.orderPendingArray.push(ele._id);
              ordObj.pending = this.orderPendingArray;
            } else if (ele.order_line_status == 10) {
              this.orderProcessingArray.push(ele._id);
              ordObj.processing = this.orderProcessingArray;
            } else if (ele.order_line_status == 20) {
              this.orderReadyPickUpArray.push(ele._id);
              ordObj.ready = this.orderReadyPickUpArray;
            }
          });

          orderWiseStatusArray.push(ordObj);
        });

        this.orderWiseArr = orderWiseStatusArray;

        this.cart_products = responce.map(obj => {
          let date = obj.created_date;
          // let time = new Date(obj.created_date);
          // let createdHours = new Date(time).getHours();
          // let createdMinutes =  new Date(time).getMinutes();
          // let createdSeconds =  new Date(time).getSeconds();
          // obj.created_time =  createdHours + ":" + createdMinutes + ":" + createdSeconds;
          obj.created_time = moment(obj.created_date).format("hh:mm A")


          let dats = date.split("GMT");

          obj.created_date = dats[0];
          // obj.created_time = date.split("T")[1].split(".0")[0];


          obj.itemCount = obj.order_lines.length;


          obj.subTotalForMearchat = 0;
          let avgArray = [];
          avgArray = obj.order_lines;
          avgArray.forEach(item => {
            obj.subTotalForMearchat = ((item.unit_price * item.qty) + item.delivery_charge) + obj.subTotalForMearchat;
          });

          return obj;
        });

        // this.cart_products = this.cart_products.filter(el => {
        //   return el.order_status !== 0;
        // });


        this.getOrdersCount();
        this.storeListData = this.cart_products;
        this.tableItems = this.cart_products;
        // console.log("new table")
        // console.log(this.tableItems)
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    searchOrders: function(keyword, type = "") {
      if (type == "orderid") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter(obj => {
              {
                return (
                  obj.order_env_number &&
                  obj.order_env_number
                    .toLowerCase()
                    .includes(keyword.toLowerCase())
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.tableItems.length;
        }
        this.blockLoading = false;
      } else if (type == "status") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.tableItems.length;
        }
        this.blockLoading = false;
      } else if (type == "paymethod") {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter(obj => {
              {
                if (keyword == 2) {
                  return obj.payment_type == 2;
                }
                if (keyword == 3) {
                  return obj.payment_type == 3;
                }
                if (keyword == 4) {
                  return obj.payment_type == 4;
                }
                if (keyword == "all") {
                  return obj;
                }
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.tableItems.length;
        }
        this.blockLoading = false;
      } else {
        let pagesize = parseInt(this.pageSize);
        if (!keyword || keyword.length === 0) {
          this.tableItems = this.storeListData.slice(0, pagesize);
          this.rowcount = this.storeListData.length;
        } else {
          this.tableItems = this.storeListData
            .filter(obj => {
              {
                return (
                  obj.order_env_number &&
                  obj.order_env_number
                    .toLowerCase()
                    .includes(keyword.toLowerCase())
                );
              }
            })
            .slice(0, pagesize);
          this.rowcount = this.tableItems.length;
        }
        this.blockLoading = false;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      this.currentPage = currentPage;
      this.getOrders();
    },
    handdleChange: function(item) {
      localStorage.setItem("cancelled_order_group_id", item._id);
      this.$router.push({ name: "Cancelled Order Lines", params: { id: item._id } });
    }
  }
};
</script>

<style>
.badge-items-status-count {
  font-weight: bolder;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 10px;
  color: #ffffff;
  line-height: 17px;
  margin-right: 5px;
  text-align: center;
}

.vdp-datepicker input {
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.merchorderlist .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchorderlist .merchprof-sbtn:hover {
  color: #000000;
}
.from-to-date .vdp-datepicker input {
  width: 100% !important;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
@media screen and (max-width: 1472px) {
  #table-content {
    overflow-x: auto;
  }
  .table-order {
    /* width: 1000px !important;
    overflow-x: scroll; */
  }
  .btn-mrc {
    margin-top: 10px;
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  /* top: 0; */
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.head-table {
  background: white !important;
}
.table-border-out {
  border: 1px solid #ddd;
}

@media screen and (max-width: 1280px) {
  #table-content {
    overflow-x: scroll;
  }

  table {
    width: 960px !important;
  }

  .img-thumbnail {
    max-width: 80px;
  }
}
</style>
